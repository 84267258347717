import './App.css';
import {useState} from "react";

function App() {
  const [songList, setSonglist] = useState(null);
  const [songs, setSongs] = useState(null);
  const [dados, setDados] = useState(null);
  const [texto, setTexto] = useState(null);

    const searchSongs = (song) => {
      fetch('http://ec2-54-161-15-73.compute-1.amazonaws.com:8080/song/' + song,{
        method: 'GET'
      })
        .then(response => response.json())
        .then(songs => setSongs(songs))
        .catch(error => console.error('Erro:', error));
    };

    const sendSong = (id) => {
      fetch('http://ec2-54-161-15-73.compute-1.amazonaws.com:8080/song/' + id,{
        method: 'POST'
      })
        .then(response => {
          if (!response.ok){
            throw new Error('Erro na requisição' + response.status);
          }else console.log(response.status)
        })
        .then(dados => setDados(dados))
        .catch(error => console.error('Erro:', error));
    };

    const handleChange = (e) => {
      setTexto(e.target.value);
    }

    const handleClick = () => {
      texto.trim();
      texto.replace(" ", "%20");
      
      searchSongs(texto);
      console.log(texto);
    }

    const clickList = (e) => {
      console.log(e);
      sendSong(e);
      alert('Música adicionada à fila :)');
    }

  return (
    <div className='app-container'>
      <h1>Peça uma música</h1>
      <input 
        type="text" 
        value={texto} 
        onChange={handleChange}
        placeholder='digite o nome da música'
      />
      <button onClick={handleClick}>Buscar</button>
      
      {songs && (
        <div className="song-list-container">
          <h2>Selecione uma das músicas:</h2>
          {songs.map((song) => (
            <div key={song.id} value={song.id} onClick={() => clickList(song.id)}className="song-item card">
              <h3>{song.title}</h3>
              <div>{song.artist}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default App;